import { useMutation } from "@apollo/client";
import { makeUniqueId } from "@apollo/client/utilities";
import { navigate } from "gatsby";
import React, { FC, useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import useCart from "../../context/cart.context";
import { ADD_BUNDLE_TO_CART } from "../../mutations";
import { cantons, formatMoney } from "../../utils";
import InputBlock from "../InputBlock";
import DatePicker from "react-datepicker";
import { RadioGroup } from "@headlessui/react";
import { VALIDATE_ZIP } from "../../mutations";
import ZipcodeSearch from "@stefanzweifel/js-swiss-cantons/src/ZipcodeSearch";
import {
  ShippingAddressState,
  useUserData,
} from "../../context/user-data.context";
import { useVoucherData } from "../../context/voucher-data.context";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorpicLayoutEffect";
interface ProductFormProps {
  data: any;
  setShowModal: any;
  shippingAddress: ShippingAddressState[] | Partial<ShippingAddressState>[];
  setShippingAddress: (value: any) => void;
  currentUser: ShippingAddressState | null;
  setCurrentUser: (value: any) => void;
  bundle: {
    note: string;
    productId: string | number;
    upsellProducts: any[];
    variant: string;
  };
  isLoading: boolean;
  setIsLoading: (value: any) => void;
  bundleItems: any;
  setBundle?: (value: any) => void;
  alter?: any;
}

const ProductForm: FC<ProductFormProps> = ({
  data,
  setShowModal,
  bundle,
  shippingAddress,
  setShippingAddress,
  currentUser,
  setCurrentUser,
  isLoading,
  setIsLoading,
  bundleItems,
  setBundle,
  alter,
}) => {
  const [addBundleToCart] = useMutation(ADD_BUNDLE_TO_CART);
  const [validateZip] = useMutation(VALIDATE_ZIP);
  const { voucherDataIsExist, getVoucherData, changeVoucherData } =
    useVoucherData();

  const memoizedVoucherData = React.useMemo(() => {
    return getVoucherData();
  }, [getVoucherData]);
  const {
    refetch,
    zipCode,
    setZipCode,
    shippingData,
    setShippingData,
    shippingCost,
    setShippingCost,
    setVoucherCode,
    voucherCode,
  } = useCart();
  const [loading, setLoading] = useState(false);
  const [dateForChecker, setDateForChecker] = useState<null | Date>(null);
  const [gender, setGender] = useState<"Frau" | "Herr" | "">("");

  const [isZipError, setIsZipError] = useState<string>("");
  useEffect(() => {
    setZipCode(zipCode);
  }, [zipCode]);
  // get query params
  // const queryParams = new URLSearchParams(typeof window !== "undefined" ? window.location.search : "");
  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      streetName: "",
      houseNumber: "",
      city: "",
      additional_information: "",
      date: "",
      time: "",
      shipping_phone: "+41",
      zip: zipCode ? zipCode : "",
      shipping_gender: gender,
      country: "CH",
      state: "",
    },
    mode: "onChange",
  });
  const {
    field: { onChange: setZip, value: zip },
  } = useController({
    name: "zip",
    control,
  });
  const {
    field: { onChange: setCanton, value: canton },
  } = useController({
    name: "state",
    control,
  });
  const {
    field: { onChange: setFirstName, value: firstName },
  } = useController({
    name: "firstName",
    control,
  });
  const {
    field: { onChange: setLastName, value: lastName },
  } = useController({
    name: "lastName",
    control,
  });
  const {
    field: { onChange: setStreetName, value: streetName },
  } = useController({
    name: "streetName",
    control,
  });
  const {
    field: { onChange: setHouseNumber, value: houseNumber },
  } = useController({
    name: "houseNumber",
    control,
  });
  const {
    field: { onChange: setCity, value: city },
  } = useController({
    name: "city",
    control,
  });
  const {
    field: { onChange: setPhoneNumber, value: phoneNumber },
  } = useController({
    name: "shipping_phone",
    control,
  });
  const {
    field: { onChange: setAdditionalInformation, value: additionalInformation },
  } = useController({
    name: "additional_information",
    control,
  });
  const {
    field: { onChange: setTime, value: time },
  } = useController({
    name: "time",
    control,
  });

  const onSubmit = async (formData) => {
    const itemParentKey = makeUniqueId("");
    setIsLoading(true);

    if (!formData.zip) {
      setShowModal(true);
      return;
    }
    const cantonChecker = new ZipcodeSearch();
    const locationData = cantonChecker.findbyZipcode(formData.zip);
    // add one day to the date
    const date = new Date(formData.date);
    date.setDate(date.getDate() + 1);

    console.log("date", date);
    const dateInFormatYYYYMMDD = date.toISOString().split("T")[0];
    const deliveryPrice = await validateZip({
      variables: {
        zip: formData.zip,
        state: locationData?.canton,
      },
    });
    const deliveryPriceFormatted =
      deliveryPrice?.data?.validateZip?.bakery?.delivery_price;
    // setDisabled(true)
    console.log("bundle.upsellProducts", bundle?.upsellProducts);

    await addBundleToCart({
      variables: {
        items: [
          {
            productId: data?.databaseId,
            quantity: 1,
            extraData: JSON.stringify({
              parent: itemParentKey,
              couponCode: voucherCode ? voucherCode : "",
              Note: bundle?.note,
              Variant: bundle?.variant,
              bbDeliveryPrice: deliveryPriceFormatted
                ? deliveryPriceFormatted
                : "",
              stamp:
                bundleItems.edges.reduce(
                (acc, upsellProduct) => {
                  const productInBundle = bundle?.upsellProducts.find(
                    (item) => item.bundledItemId === upsellProduct.bundledItemId
                  );
                  console.log("productInBundle", productInBundle);
                  return {
                    ...acc,
                    [upsellProduct.bundledItemId]: {
                      // ...upsellProduct,
                      discount: "",
                      // bundledItemId: upsellProduct.bundledItemId,
                      product_id: upsellProduct.node.databaseId,
                      quantity: productInBundle?.quantity ? productInBundle.quantity : 0,
                      // optional_selected: "yes",
                    }
                  };
                },
                {}
              ),
              isCoupon: false,
              ShippingData: {
                ...formData,
                gender: gender,
                zipCode: zip,
                state: locationData?.canton,
                date: dateInFormatYYYYMMDD,
                time: time,
              },
            }),
          },
        ],
      },
    })
      .then(() => {
        setVoucherCode("");
        const shippingData: ShippingAddressState = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          streetName: formData.streetName,
          houseNumber: formData.houseNumber,
          city: formData.city,
          zipCode: formData.zip,
          phoneNumber: formData.shipping_phone,
          gender: gender,
        };
        const shippingAddressMatch = shippingAddress.find((address) => {
          return (
            address.firstName === shippingData.firstName &&
            address.lastName === shippingData.lastName &&
            address.streetName === shippingData.streetName &&
            address.houseNumber === shippingData.houseNumber &&
            address.city === shippingData.city &&
            address.zipCode === shippingData.zipCode &&
            address.phoneNumber === shippingData.phoneNumber
          );
        });

        !shippingAddressMatch &&
          setShippingAddress([...shippingAddress, shippingData]);
        // console.log(shippingAddress);
      })
      .then(() => refetch())
      .then(() => {
        setIsLoading(false);
        navigate("/cart");
      });
  };

  const getFormattedDate = (daysAfterNow: number) => {
    return new Date(new Date().setDate(new Date().getDate() + daysAfterNow));
  };

  const tomorrow = getFormattedDate(1);
  const afterTomorow = getFormattedDate(2);
  const currentHours = new Date().getHours();
  const minimalDateForDelivery =
    currentHours <= 20
      ? currentHours === 20 && new Date().getMinutes() > 0
        ? afterTomorow
        : tomorrow
      : afterTomorow;
  // const deliverDate = minimalDateForDelivery.toISOString().split("T")[0];
  const holidays = shippingData?.holidays || [];
  const formattedHolidays =
    holidays &&
    holidays.map((holiday: string) => {
      const date = holiday.split("/").reverse().join("-");
      return new Date(date);
    });

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZip(e.target.value);
    const zipStore = new ZipcodeSearch();
    const locationData = zipStore.findbyZipcode(e.target.value);
    if (locationData && locationData.canton) {
      validateZip({
        variables: {
          zip: e.target.value,
          state: locationData.canton,
          week_day: String(dateForChecker),
        },
      })
        .then((res) => {
          const delivery = res.data?.validateZip?.bakery?.delivery_price;
          console.log("delivery", delivery);
          setShippingData(res.data.validateZip.bakery);
          !zipCode && setZipCode(e.target.value);
          delivery &&
            setShippingCost([
              ...shippingCost,
              {
                zipCode: e.target.value as string,
                price: delivery as number,
              },
            ]);
          setIsZipError("");
        })
        .catch((err) => {
          setIsZipError(err.message);
        });
    } else {
      setShippingData(null);
      setIsZipError("Bitte geben Sie eine gültige Postleitzahl ein");
    }
  };

  const filterWorkingDays = (date: Date) => {
    const day = date.getDay();
    console.log(day);
    const workingDays = shippingData?.working_days;
    return workingDays?.includes(day);
  };
  const calculateTotal = () => {
    return (
      +data.price?.split(";")[1] +
      bundle.upsellProducts.reduce((prev, curr) => prev + curr.price, 0)
    );
  };
  useEffect(() => {
    if (dateForChecker) {
      console.log("useEd");
      setShippingData(null);
      const zipStore = new ZipcodeSearch();
      const locationData = zipStore.findbyZipcode(zip);
      if (locationData && locationData.canton) {
        validateZip({
          variables: {
            zip,
            state: locationData.canton,
            week_day: dateForChecker.toLocaleDateString(),
          },
        })
          .then((res) => {
            console.log("working");
            const delivery = res.data?.validateZip?.bakery?.delivery_price;
            console.log(res.data.validateZip.bakery);
            setShippingData(res.data.validateZip.bakery);
            delivery &&
              setShippingCost([
                ...shippingCost,
                {
                  zipCode: zip,
                  price: delivery as number,
                },
              ]);
            setIsZipError("");
          })
          .catch((err) => {
            console.log("err");
            setIsZipError(err.message);
          });
      } else {
        setShippingData(null);
        console.log("isn't working");
        setIsZipError("Bitte geben Sie eine gültige Postleitzahl ein");
      }
    }
  }, [dateForChecker]);
  useEffect(() => {
    currentUser &&
      (() => {
        setCity(currentUser?.city);
        setFirstName(currentUser?.firstName);
        setLastName(currentUser?.lastName);
        setStreetName(currentUser?.streetName);
        setHouseNumber(currentUser?.houseNumber);
        setZip(currentUser?.zipCode);
        setPhoneNumber(currentUser?.phoneNumber);
        setGender(currentUser.gender as "Frau" | "Herr");
      })();
  }, [currentUser]);
  useEffect(() => {
    if (memoizedVoucherData?.shipping) {
      setCity(memoizedVoucherData?.shipping.city);
      setFirstName(memoizedVoucherData?.shipping.firstName);
      setLastName(memoizedVoucherData?.shipping.lastName);
      setStreetName(memoizedVoucherData?.shipping.streetName);
      setHouseNumber(memoizedVoucherData?.shipping.houseNumber);
      setZip(memoizedVoucherData?.shipping.zipCode);
      setPhoneNumber(memoizedVoucherData?.shipping.phoneNumber);
    }
    // if(zip){
      const canton = new ZipcodeSearch().findbyZipcode(zip)?.canton;
      if(canton){
        console.log("zip", zip, "canton", canton);
        validateZip({
          variables: {
            zip,
            state: canton,
            // week_day: dateForChecker?.toLocaleDateString(),
          },
        })
          .then((res) => {
            console.log("working");
            const delivery = res.data?.validateZip?.bakery?.delivery_price;
            console.log(res.data.validateZip.bakery);
            setShippingData(res.data.validateZip.bakery);
            setIsZipError("");
          })
          .catch((err) => {
            console.log("err");
            setIsZipError(err.message);
          });
      }
    // }
  }, []);
  useEffect(() => {
    const canton = new ZipcodeSearch().findbyZipcode(zip)?.canton;
    if(canton){
      console.log("zip", zip, "canton", canton);
      validateZip({
        variables: {
          zip,
          state: canton,
          // week_day: dateForChecker?.toLocaleDateString(),
        },
      })
        .then((res) => {
          console.log("working");
          const delivery = res.data?.validateZip?.bakery?.delivery_price;
          console.log(res.data.validateZip.bakery);
          setShippingData(res.data.validateZip.bakery);
          setIsZipError("");
        })
        .catch((err) => {
          console.log("err");
          setIsZipError(err.message);
        });
    }
    // }
  }, [zip])
  useEffect(() => {

    if (bundle.upsellProducts.length === 0){
      const predefinedSupplements = memoizedVoucherData?.supplements &&
        memoizedVoucherData?.supplements.map((supplement: any) => {
          const item = alter?.bundleItems?.edges?.find(
            (item: any) => item.bundledItemId === supplement.bundledItemId
          );
          console.log("item", alter, supplement);
          return {
            id: item?.node?.databaseId,
            quantity: supplement.quantity,
            price: +supplement?.price,
            bundledItemId: supplement?.bundledItemId,
            name: item?.node?.name,
            productId: supplement?.product_id,
            product_id: supplement?.product_id,
            optional_selected: "yes"
          };
        });
      predefinedSupplements && bundle.upsellProducts.length === 0 && setBundle({
        ...bundle,
        upsellProducts: predefinedSupplements
      });
    }
  }, [memoizedVoucherData]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="relative flex flex-col px-8 pt-8 bg-white lg:pt-0 lg:px-0 "
    >
      <h2 className="pb-3 font-serif text-3xl text-center border-b-2 border-black">
        Ihre Lieferangaben
      </h2>
      <div>
        <RadioGroup
          value={gender}
          onChange={setGender}
          className="text-left flex flex-row gap-x-5 mt-4"
        >
          <RadioGroup.Option value="Frau">
            {({ checked }) => (
              <div className="flex items-center space-x-2 cursor-pointer">
                <input
                  checked={checked || gender === "Frau"}
                  className="text-darkGray focus:ring-0 cursor-pointer"
                  type="radio"
                  name="variant2"
                />
                <span
                  onClick={() => (checked = !checked)}
                  className={!checked ? "text-standardGray" : undefined}
                >
                  Frau
                </span>
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="Herr">
            {({ checked }) => (
              <div className="flex items-center space-x-2 cursor-pointer">
                <input
                  checked={checked || gender === "Herr"}
                  className="text-darkGray focus:ring-0 cursor-pointer"
                  type="radio"
                  name="variant2"
                />
                <span
                  onClick={() => (checked = !checked)}
                  className={!checked ? "text-standardGray" : undefined}
                >
                  Herr
                </span>
              </div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </div>
      <InputBlock
        label="Vorname"
        required={true}
        className={"w-full mt-6"}
        value={firstName}
        type="text"
        onChange={(e) => setFirstName(e.target.value)}
      />
      <InputBlock
        label="Nachname"
        required={true}
        className={"w-full mt-6"}
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <div>
        <div className="w-full mt-6">
          <div className="flex gap-4 mt-1">
            <InputBlock
              label="Strasse"
              className={"!w-[70%]"}
              required={true}
              value={streetName}
              type="text"
              onChange={(e) => setStreetName(e.target.value)}
            />
            <InputBlock
              label="Hausnummer"
              className={"!w-[30%]"}
              required={true}
              value={houseNumber}
              type="text"
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="w-full mt-6">
        <div className="flex gap-4 mt-1">
          <div className="flex flex-col">
            <label className="text-standardGray">PLZ *</label>
            <input
              value={zip}
              className="customInput"
              required
              onChange={handleZipChange}
              type="text"
            />
            {isZipError && (
              <p className="text-xs mt-1 text-red-500">{isZipError}</p>
            )}
          </div>
          <InputBlock
            label="Ort"
            className={"w-full"}
            value={city}
            required={true}
            type="text"
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <InputBlock
          className="w-full mt-6"
          label="Telefonnummer"
          type="text"
          value={phoneNumber}
          placeholder="+41 ..."
          required
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <InputBlock
          className="w-full mt-6"
          label="Zusätzliche Adressinformationen"
          type="text"
          placeholder="Stockwerk, Zugang von der Strasse, etc."
          value={additionalInformation}
          onChange={(e) => setAdditionalInformation(e.target.value)}
        />
        {shippingData && (
          <section aria-labelledby="payment-heading" className="">
            <div className="mt-6 ">
              <div className="flex gap-4">
                <div className="w-4/6 space-y-1">
                  <label
                    htmlFor="datum"
                    className="block text-[14px] font-normal text-[#808080]"
                  >
                    Lieferdatum *
                  </label>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => (
                      <DatePicker
                        required
                        minDate={minimalDateForDelivery}
                        dateFormat="dd/MM/yyyy"
                        placeholderText=""
                        onChange={(date) => {
                          // remove on day from here
                          setDateForChecker(date);
                          field.onChange(date);
                        }}
                        selected={field.value}
                        filterDate={filterWorkingDays}
                        excludeDays={formattedHolidays}
                        className="customInput focus:outline-none focus:ring-0"
                        calendarStartDay={1}
                      />
                    )}
                  />
                </div>

                {shippingData?.working_time &&
                shippingData.working_days &&
                dateForChecker ? (
                  <div className="w-2/6">
                    <label
                      htmlFor="lieferzeit"
                      className="block text-[14px] font-normal text-[#808080]"
                    >
                      Lieferzeit *
                    </label>
                    <div className="mt-1">
                      <select
                        required={true}
                        id="lieferzeit"
                        onChange={(e) => setTime(e.target.value)}
                        className="block w-full border-gray-300 shadow-sm text-[14px] font-normal text-[#808080] focus:ring-black focus:border-black sm:text-sm"
                      >
                        {Object.values(["", ...shippingData.working_time]).map(
                          (times) => {
                            const timeWithoutAmPm = String(times)
                              .replace(" am", "")
                              .replace(" am", "")
                              .replace(" pm", "")
                              .replace(" pm", "");
                            return (
                              <option value={String(times)}>
                                {timeWithoutAmPm}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="w-2/6">
                    <label
                      htmlFor="lieferzeit"
                      className="block w-full border-gray-300 shadow-sm text-[14px] font-normal text-[#808080] sm:text-sm"
                    >
                      Lieferzeit *
                    </label>
                    <div className="mt-1">
                      <select
                        disabled={true}
                        className="block w-full py-[6.2px] border-gray-200 shadow-sm text-[14px] font-normal text-[#808080]"
                      ></select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        <button
          type="submit"
          className="w-full px-4 py-3 text-base font-light text-white uppercase border border-transparent mt-8 bg-olive hover:bg-oliveStrong focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
        >
          In den Warenkorb ({formatMoney(calculateTotal())})
        </button>
        {!getVoucherData() && <a
          href={`/gutscheine/gutschein-${data.slug}`}
          className="block w-full px-4 py-3 mt-3 text-base font-light text-center text-white uppercase border border-transparent bg-standardGray hover:bg-graniteGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
        >
          Als gutschein bestellen
        </a>}
      </div>
    </form>
  );
};

export default ProductForm;
