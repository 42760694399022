// @ts-nocheck
import React, { useEffect, useState } from "react";
import Modal from "../../components/modal";
import ModalPdf from "../../components/ModalPdf";
import PDF from "../../components/pdf";
import { ProductList } from "./ProductList";
import Title from "../../components/title";
import CouponForm from "./CouponForm";
import Detailed from "./Detailed";
import { useIsMobile } from "../../hooks";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Preloader from "../Preloader";
import PdfModalView from "../PdfModalView";

export const Coupon = ({ data, alter }) => {
  const [maxCharVoucher, setMaxCharVoucher] = useState<number>(0);
  const [modalPdf, setModalPdf] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pdf, setPdf] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  console.log("using coupon template");
  const isMobile = useIsMobile();
  const initialState = {
    productId: data.databaseId,
    upsellProducts: [],
    pdf,
  };

  const [bundle, setBundle] = useState(initialState);

  useEffect(() => {
    setBundle({ ...bundle, pdf });
  }, [pdf]);

  const ref = React.createRef<HTMLButtonElement>();


  const addUpsellProduct = (
    productId: number,
    quantity: number,
    price: number,
    bundledItemId: number,
    name: string
  ) => {
    setIsLoading(true);
    if (
      bundle.upsellProducts.find(
        (upsellProduct) => upsellProduct.productId === productId
      ) !== undefined
    ) {
      setBundle({
        ...bundle,
        upsellProducts: [
          ...[
            ...bundle.upsellProducts.map((u) => {
              if (u.productId === productId) {
                return { ...u, quantity };
              } else {
                return u;
              }
            }),
          ],
        ],
      });
    } else {
      setBundle({
        ...bundle,
        upsellProducts: [
          ...bundle.upsellProducts.filter((u) => u.productId !== productId),
          { productId, quantity, price, bundledItemId, name },
        ],
      });
    }
    setIsLoading(false);
  };
  const removeUpsellProduct = (productId: number) => {
    setIsLoading(true);
    setBundle({
      ...bundle,
      upsellProducts: bundle.upsellProducts.filter(
        (upsellProduct) => upsellProduct.productId !== productId
      ),
    });
    setIsLoading(false);
  };

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <div className="pt-12 pb-12 mx-auto max-w-7xl">
        <Title
          title={`${data?.name ?? ""}`}
          subtitle={data.shortDescription ?? ""}
          breadcrumb={"Produkte"}
        />
        <div className="gap-6 mt-12 transition-all duration-300 lg:grid lg:grid-cols-3">
          <div className="relative flex flex-col px-8 bg-white lg:px-0 ">
            <h2 className="pb-3 font-serif text-3xl text-center border-b-2 border-black">
              Sie schenken...
            </h2>
            <Detailed product={data} />

            <div className="relative flex flex-col pt-8 bg-white lg:pt-0 lg:px-0 mt-20">
              <h2 className="pb-3 font-serif text-3xl text-center border-b-2 border-black sm:pt-8 lg:pt-0">
                Supplements
              </h2>
              <ProductList
                bundleItems={alter?.bundleItems}
                dataItems={data?.bundleItems}
                addUpsellProduct={addUpsellProduct}
                removeUpsellProduct={removeUpsellProduct}
              />
            </div>
            <div className="px-8 lg:px-0">
              <p className="pt-5 pb-20 text-sm text-standardGray">
                ** Der Verkauf von Champagner an Personen unter 18 Jahren ist aus
                gesetzlichen Gründen untersagt. Mit der Auswahl eines
                Champagners bestätigen Sie, dass der/die EmpfängerIn 18 Jahre
                oder älter ist.
              </p>
            </div>
          </div>
          <div className="flex flex-col text-center">
            <div className="relative p-3 bg-olivelight w-full">
              <img
                src="/ribbon.png"
                loading="lazy"
                width={85}
                className="absolute top-0 left-0"
                alt=""
              />
              <img
                src="/ribbon.png"
                loading="lazy"
                width={85}
                className="absolute bottom-0 right-0 rotate-180"
                alt=""
              />
              <figure className="w-full">
                <img
                  className="w-full mx-auto"
                  alt=""
                  src={
                    data?.data?.heroImage?.heroImage?.sourceUrl ??
                    "/bestellen.jpg"
                  }
                />
              </figure>
              <div className="px-8 py-16 lg:px-5">
                {/*<p className="font-bold">Das ist unsere {data.data.name}.</p>*/}
                {/*<p*/}
                {/*  className=""*/}
                {/*  dangerouslySetInnerHTML={{ __html: data.data.description }}*/}
                {/*/>*/}
                <p
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></p>
              </div>
              <div className="self-center flex-1 w-full px-5 py-5 space-y-5">
                <p className="pb-2 font-serif text-2xl border-b-2 border-black">
                  Ihre persönliche <br /> Grussnachricht
                </p>
                <textarea
                  wrap="hard"
                  maxLength={500}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setMaxCharVoucher(e.target.value.length);
                    setPdf(e.target.value);
                  }}
                  name=""
                  id=""
                  className={`w-full bg-white border border-[#d9d9d9] outline-none resize-none  focus-within:outline-none focus:ring-0 ${
                    !setPdf ? "text-standardGray" : "text-black"
                  }`}
                  rows={5}
                  placeholder="Hier können Sie Ihre Grussnachricht schreiben...."
                ></textarea>
                <p className="!mt-0 text-standardGray text-[14px]">
                  Verbleibende Zeichen {500 - maxCharVoucher}
                </p>
                <p className="!mt-0 text-standardGray text-[14px]">
                  Emojis werden zurzeit nicht unterstützt.
                </p>
                {!isMobile && (
                  <button
                    onClick={() => setModalPdf(true)}
                    className="flex items-center w-full px-4 py-3 space-x-5 text-base font-medium text-black border border-transparent focus:outline-none"
                  >
                    <img src="/pfeil.png" loading="lazy" alt="" />
                    <p className="uppercase">Vorschau Ihres Gutscheins</p>
                  </button>
                )}
                {isMobile && (
                  <button className="flex items-center w-full px-4 py-3 space-x-5 text-base font-medium text-black border border-transparent focus:outline-none">
                    <img src="/pfeil.png" loading="lazy" alt="Download " />
                    <PDFDownloadLink
                      document={
                        <PDF bundle={bundle} text={pdf} product={data} />
                      }
                      fileName="voucher_example.pdf"
                      className="flex items-center w-full px-4 py-3 space-x-5 text-base font-medium text-black border border-transparent focus:outline-none"
                    >
                      <p className="uppercase">Vorschau Ihres Gutscheins</p>
                    </PDFDownloadLink>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="relative flex flex-col px-8 pt-8 bg-white lg:pt-0 lg:px-0 ">
            <CouponForm
              setIsLoading={setIsLoading}
              bundle={bundle}
              data={data}
              bundleItems={alter?.bundleItems}
            />
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        pCancelButtonRef={ref}
      />
      {!isMobile && (
        <ModalPdf show={modalPdf} setShow={setModalPdf} bundle={bundle} text={pdf} product={data}>
          {/* <PDF bundle={bundle} text={pdf} product={data} /> */}

        </ModalPdf>
      )}
    </>
  );
};
