import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import React from "react";
import fetch from "isomorphic-fetch";

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  // @ts-ignore
  fetch,
  uri: "https://ru06wamdpv.preview.infomaniak.website/graphql",
});

export const wrapRootElement = (element: React.ReactNode) => {

  return <ApolloProvider client={client}>{element}</ApolloProvider>;
};
