import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { makeUniqueId } from "@apollo/client/utilities";
import InputBlock from "../InputBlock";
import useCart from "../../context/cart.context";
import { useMutation } from "@apollo/client";
import { ADD_BUNDLE_TO_CART, VALIDATE_ZIP } from "../../mutations";
import { RadioGroup } from "@headlessui/react";
import { formatMoney } from "../../utils";
import { useController, useForm } from "react-hook-form";
import { useCheckZip } from "../../hooks";
import ZipcodeSearch from "@stefanzweifel/js-swiss-cantons/src/ZipcodeSearch";
import {
  ShippingAddressState,
  UserDataState,
  useUserData,
} from "../../context/user-data.context";

const CouponForm = ({ bundle, data, setIsLoading, bundleItems }) => {
  const { refetch, setZipCode, zipCode, updateCart, cart } = useCart();

  const [addBundleToCart] = useMutation(ADD_BUNDLE_TO_CART);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [validateZip] = useMutation(VALIDATE_ZIP);
  const { userData: shippingAddress, setUserData: setShippingAddress } =
    useUserData();
  const [currentUser, setCurrentUser] = useState<ShippingAddressState>(null);
  const [gender, setGender] = useState<"Frau" | "Herr" | "">("");
  const verifyZip = useCheckZip();
  const [deliveryOption, setDeliveryOption] = useState(
    "per-e-mail-an-mich-senden"
  );
  const [isZipError, setIsZipError] = useState<string>("");

  useEffect(() => {
    setZipCode(zipCode);
  }, [zipCode]);

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      streetName: "",
      houseNumber: "",
      zipCode: zipCode ? zipCode : "",
      city: "",
      additional_information: "",
      date: "",
      time: "",
      shipping_phone: "",
    },
  });

  const {
    field: { onChange: setZip, value: zip },
  } = useController({
    name: "zipCode",
    control,
  });
  const {
    field: { onChange: setFirstName, value: firstName },
  } = useController({
    name: "firstName",
    control,
  });
  const {
    field: { onChange: setLastName, value: lastName },
  } = useController({
    name: "lastName",
    control,
  });
  const {
    field: { onChange: setPhone, value: phone },
  } = useController({
    name: "shipping_phone",
    control,
  });
  const {
    field: { onChange: setStreetName, value: streetName },
  } = useController({
    name: "streetName",
    control,
  });
  const {
    field: { onChange: setHouseNumber, value: houseNumber },
  } = useController({
    name: "houseNumber",
    control,
  });
  const {
    field: { onChange: setCity, value: city },
  } = useController({
    name: "city",
    control,
  });

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const itemParentKey = makeUniqueId("");
    const cantonChecker = new ZipcodeSearch();
    const locationData = cantonChecker.findbyZipcode(formData.zipCode);
    if (locationData && locationData.canton) {
      const checkZip = await verifyZip(formData.zipCode, locationData.canton);
      !zipCode && checkZip.id && setZipCode(formData.zipCode);
      const deliveryPrice = await validateZip({
        variables: {
          zip: formData.zipCode,
          state: locationData?.canton,
        },
      });
      const deliveryPriceFormatted =
        deliveryPrice?.data?.validateZip?.bakery?.delivery_price;
      addBundleToCart({
        variables: {
          items: [
            {
              productId: data?.databaseId,
              quantity: 1,
              extraData: JSON.stringify({
                voucherDelivery: deliveryOption,
                voucherDeliveryMeta:
                  deliveryOption === "per-e-mail-an-mich-senden"
                    ? "per E-Mail an mich senden"
                    : deliveryOption === "per-post-an-mich-senden"
                    ? "per Post an mich senden"
                    : "per Post an die/den Beschenkten sended",
                bbDeliveryPrice: deliveryPriceFormatted
                  ? deliveryPriceFormatted
                  : "",
                parent: itemParentKey,
                pdf: bundle?.pdf,
                stamp: bundleItems.edges.reduce((acc, upsellProduct) => {
                  const productInBundle = bundle?.upsellProducts.find(
                    (item) => item.productId === upsellProduct.node.databaseId
                  );
                  return {
                    ...acc,
                    [upsellProduct.bundledItemId]: {
                      // ...upsellProduct,
                      discount: "",
                      // bundledItemId: upsellProduct.bundledItemId,
                      product_id: upsellProduct.node.databaseId,
                      quantity: productInBundle?.quantity
                        ? productInBundle.quantity
                        : 0,
                      // optional_selected: "yes",
                    },
                  };
                }, {}),
                isCoupon: true,
                ShippingData: {
                  ...formData,
                  gender: gender,
                  zipCode: formData.zipCode,
                  state: locationData?.canton,
                },
              }),
            },
          ],
        },
      })
        .then(() => {
          const shippingData: ShippingAddressState = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            streetName: formData.streetName,
            houseNumber: formData.houseNumber,
            city: formData.city,
            zipCode: formData.zip,
            phoneNumber: formData.shipping_phone,
            gender: gender,
          };
          const shippingAddressMatch = shippingAddress.find((address) => {
            return (
              address.firstName === shippingData.firstName &&
              address.lastName === shippingData.lastName &&
              address.zipCode === shippingData.zipCode
            );
          });

          !shippingAddressMatch &&
            deliveryOption !== "per-e-mail-an-mich-senden" &&
            setShippingAddress([
              ...shippingAddress,
              shippingData,
            ] as UserDataState[]);
        })
        .then(() => refetch())
        .then(() => {
          setIsLoading(false);
          navigate("/cart");
        });
    } // } else setShowModal(true);
  };
  const me = data.metaData.find((m) => m.key === "_product_addons").value;

  const calculateTotal = (value: number = 0) => {
    return (
      +data.price?.split(";")[1] +
      bundle.upsellProducts.reduce((prev, curr) => prev + curr.price, value)
    );
  };

  const handleZipChange = (e) => {
    setZip(e.target.value);
    const cantonChecker = new ZipcodeSearch();
    const locationData = cantonChecker.findbyZipcode(e.target.value);
    if (locationData && locationData.canton) {
      // setZip(e.target.value);
      validateZip({
        variables: { zip: e.target.value, state: locationData.canton },
      }).then((r) => {
        if (r.data.validateZip.bakery) {
          setIsZipError("");
          return;
        } else {
          setIsZipError("Wir liefern leider nicht in diese Region.");
        }
      });
      setIsZipError("");
    } else {
      setIsZipError("Bitte geben Sie eine gültige Postleitzahl ein");
    }
  };

  // useEffect(() => {
  //   updateCart({
  //     shippingTotal:
  //       deliveryOption !== "per-e-mail-an-mich-senden"
  //         ? cart.shippingTotal + 5
  //         : cart.shippingTotal,
  //     total: deliveryOption !== "per-e-mail-an-mich-senden" ? 5 : 0,F
  //   });
  // }, [deliveryOption]);
  useEffect(() => {
    console.log(cart.appliedCoupons);
  }, [cart]);
  return (
    <>
      <h2 className="pb-3 font-serif text-3xl text-center border-b-2 border-black">
        Gutschein Versandart
      </h2>
      {/*<div dangerouslySetInnerHTML={{ __html: JSON.parse(data.data.addons_html) }}></div>*/}
      <RadioGroup
        as="div"
        className="flex flex-col py-5 space-y-2"
        value={deliveryOption}
        onChange={setDeliveryOption}
      >
        <RadioGroup.Option value="per-e-mail-an-mich-senden">
          {({ checked }) => (
            <div className="flex items-center space-x-3 cursor-pointer">
              <div
                className={`w-4 h-4 rounded-full bg-white flex-shrink-0 transition-all ${
                  checked
                    ? "border-darkGray border-[5px]"
                    : "border-standardGray border-[2px]"
                }`}
              ></div>
              <span className={checked ? "text-black" : "text-standardGray"}>
                per E-Mail an mich senden
              </span>
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="per-post-an-mich-senden">
          {({ checked }) => (
            <div className="flex items-center space-x-3 cursor-pointer">
              <div
                className={`w-4 h-4 rounded-full bg-white flex-shrink-0 transition-all ${
                  checked
                    ? "border-darkGray border-[5px]"
                    : "border-standardGray border-[2px]"
                }`}
              ></div>{" "}
              <span className={checked ? "text-black" : "text-standardGray"}>
                per Post an mich senden (+ CHF 5.00 Versandkosten)
              </span>
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="per-post-an-die-den-beschenkten-sended">
          {({ checked }) => (
            <div className="flex items-center space-x-3 cursor-pointer">
              <div
                className={`w-4 h-4 rounded-full bg-white flex-shrink-0 transition-all ${
                  checked
                    ? "border-darkGray border-[5px]"
                    : "border-standardGray border-[2px]"
                }`}
              ></div>
              <span className={checked ? "text-black" : "text-standardGray"}>
                per Post an die/den Beschenkte:n senden (+ CHF 5.00
                Versandkosten)
              </span>
            </div>
          )}
        </RadioGroup.Option>
      </RadioGroup>
      <h2 className="pb-3 mt-20 font-serif text-3xl text-center border-b-2 border-black">
        Wer wird beschenkt?
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mt-5 space-y-5"
      >
        <RadioGroup
          value={gender}
          onChange={setGender}
          className="text-left flex flex-row gap-x-5 mt-4"
        >
          <RadioGroup.Option value="Frau">
            {({ checked }) => (
              <div className="flex items-center space-x-2 cursor-pointer">
                <input
                  checked={checked || gender === "Frau"}
                  required={
                    !checked &&
                    deliveryOption === "per-post-an-die-den-beschenkten-sended"
                  }
                  className="text-darkGray focus:ring-0 cursor-pointer"
                  type="radio"
                  name="variant2"
                />
                <span
                  onClick={() => (checked = !checked)}
                  className={!checked ? "text-standardGray" : undefined}
                >
                  Frau
                </span>
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="Herr">
            {({ checked }) => (
              <div className="flex items-center space-x-2 cursor-pointer">
                <input
                  checked={checked || gender === "Herr"}
                  required={
                    !checked &&
                    deliveryOption === "per-post-an-die-den-beschenkten-sended"
                  }
                  className="text-darkGray focus:ring-0 cursor-pointer"
                  type="radio"
                  name="variant2"
                />
                <span
                  onClick={() => (checked = !checked)}
                  className={!checked ? "text-standardGray" : undefined}
                >
                  Herr
                </span>
              </div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
        <InputBlock
          label="Vorname der/des Beschenkten"
          required={deliveryOption === "per-post-an-die-den-beschenkten-sended"}
          className="w-full"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputBlock
          label="Nachname der/des Beschenkten"
          className="w-full"
          type="text"
          value={lastName}
          required={deliveryOption === "per-post-an-die-den-beschenkten-sended"}
          onChange={(e) => setLastName(e.target.value)}
        />

        <div className="flex space-x-3">
          <InputBlock
            className="w-[70%]"
            required={
              deliveryOption === "per-post-an-die-den-beschenkten-sended"
            }
            type="text"
            label="Strasse der/des Beschenkten"
            value={streetName}
            onChange={(e) => setStreetName(e.target.value)}
          />
          <InputBlock
            className="w-[30%]"
            label="Hausnummer"
            required={
              deliveryOption === "per-post-an-die-den-beschenkten-sended"
            }
            type="text"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
          />
        </div>

        <div className="flex space-x-3">
          <div className="flex flex-col">
            <label className="text-standardGray">PLZ</label>
            <input
              value={zip}
              className="customInput"
              required
              onChange={handleZipChange}
              type="text"
            />
            {isZipError && (
              <p className="text-xs mt-1 text-red-500">{isZipError}</p>
            )}
          </div>
          <InputBlock
            label="Ort"
            required={
              deliveryOption === "per-post-an-die-den-beschenkten-sended"
            }
            type="text"
            className="w-full"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <InputBlock
          label="Telefonnummer der/des Beschenkten"
          required={deliveryOption === "per-post-an-die-den-beschenkten-sended"}
          type="text"
          className="w-full"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <button
          type="submit"
          disabled={!!cart?.appliedCoupons?.length}
          className={`w-full px-4 py-3 mt-5 text-base font-light text-white uppercase border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500 ${!!cart?.appliedCoupons?.length ? "bg-graniteGray cursor-not-allowed" : "bg-olive hover:bg-oliveStrong"}`}
        >
          In den Warenkorb (
          {formatMoney(
            calculateTotal(
              deliveryOption === "beschenkte senden" ||
                deliveryOption === "mich-senden"
                ? 5
                : 0
            )
          )}
          )
        </button>
      </form>
    </>
  );
};

export default CouponForm;
