import React from "react";
import { Skeleton } from "@material-ui/lab";

interface TemplateLoaderProps {
  isProduct?: boolean;
}

const TemplateLoader: React.FC<TemplateLoaderProps> = ({isProduct= true}) => {
  return (
    <div className="bg-white w-full">
    <div className="pt-12 pb-12 mx-auto w-full lg:max-w-7xl !h-screen px-3">
      <div className="text-center mb:pt-0">
        <Skeleton
          variant="text"
          className="mx-auto mt-1 mb-8 font-serif text-4xl font-normal text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl"
        />
        <Skeleton
          variant="text"
          className="mx-auto text-xl font-light leading-relaxed text-black max-w-7xl"
        />
      </div>
      <div className="gap-6 mt-12 transition-all duration-300 lg:grid lg:grid-cols-3">
        <div className="relative flex flex-col px-8 bg-white lg:px-0 ">
          <Skeleton
            variant="text"
            className="pb-3 font-serif text-3xl text-center"
          />
          <div className="flex flex-row">
            <Skeleton
              variant="circle"
              className="w-5 h-5 rounded-full bg-gray-300 flex-shrink-0 transition-all border-gray border-[2px] relative"
            />
            <Skeleton variant="text" className="ml-2 w-full" />
          </div>
          <div className="relative flex flex-col pt-8 bg-white lg:pt-0 lg:px-0 lg:mt-20">
            <Skeleton
              variant="text"
              className="pb-3 font-serif text-3xl text-center sm:pt-8 lg:pt-0"
            />
            <ul>
              <li className="flex py-6 sm:py-10">
                <div className="flex-shrink-0 mt-1 sm:mt-0">
                  <Skeleton
                    variant="rect"
                    className="object-contain object-center !w-16 !h-16 sm:!w-20 sm:!h-20"
                  />
                </div>
                <div className="flex flex-col justify-between flex-1 ml-4 sm:ml-9">
                  <div className="relative sm:flex sm:pr-0 justify-between">
                    <div className="flex-1 w-[30%]">
                      <Skeleton variant="text" className="flex space-x-1" />
                      <Skeleton
                        variant="text"
                        className="mt-1 font-bold text-[16px] text-gray-900"
                      />
                    </div>
                    <div className="mt-4 text-right sm:mt-0">
                      <Skeleton
                        variant="text"
                        className="max-w-full border ml-10 border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left  focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm w-[70px] h-9"
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex py-6 sm:py-10">
                <div className="flex-shrink-0 mt-1 sm:mt-0">
                  <Skeleton
                    variant="rect"
                    className="object-contain object-center !w-16 !h-16 sm:!w-20 sm:!h-20"
                  />
                </div>
                <div className="flex flex-col justify-between flex-1 ml-4 sm:ml-9">
                  <div className="relative sm:flex sm:pr-0 justify-between">
                    <div className="flex-1 w-[30%]">
                      <Skeleton variant="text" className="flex space-x-1" />
                      <Skeleton
                        variant="text"
                        className="mt-1 font-bold text-[16px] text-gray-900"
                      />
                    </div>
                    <div className="mt-4 text-right sm:mt-0">
                      <Skeleton
                        variant="text"
                        className="max-w-full border ml-10 border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left  focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm w-[70px] h-9"
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex py-6 sm:py-10">
                <div className="flex-shrink-0 mt-1 sm:mt-0">
                  <Skeleton
                    variant="rect"
                    className="object-contain object-center !w-16 !h-16 sm:!w-20 sm:!h-20"
                  />
                </div>
                <div className="flex flex-col justify-between flex-1 ml-4 sm:ml-9">
                  <div className="relative sm:flex sm:pr-0 justify-between">
                    <div className="flex-1 w-[30%]">
                      <Skeleton variant="text" className="flex space-x-1" />
                      <Skeleton
                        variant="text"
                        className="mt-1 font-bold text-[16px] text-gray-900"
                      />
                    </div>
                    <div className="mt-4 text-right sm:mt-0">
                      <Skeleton
                        variant="text"
                        className="max-w-full border ml-10 border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left  focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm w-[70px] h-9"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative flex flex-col lg:my-[50px] text-center">
          <div className="bg-[#f2f2f2] p-3 w-full flex flex-col items-center">
            <Skeleton
              variant="rect"
              className="w-full h-[300px]"
              style={{ height: "300px" }}
            />
            <div className="my-4 flex w-full flex-col justify-center items-center text-center lg:px-0">
              <Skeleton variant="text" className="w-[90%]" />
              <Skeleton variant="text" className="w-[80%]" />
            </div>
            <div className="w-[98%] self-center py-5 space-y-3 sm:w-full">
              <Skeleton
                variant="text"
                className="pb-2 font-serif text-2xl border-b-2"
              />
              <div className="flex flex-row">
                <Skeleton
                  variant="circle"
                  className="w-5 !h-5 rounded-full bg-gray-300 flex-shrink-0 transition-all border-gray border-[2px] relative"
                />
                <Skeleton variant="text" className="ml-2 !w-[30%]" />
              </div>
              <div className="flex flex-row">
                <Skeleton
                  variant="circle"
                  className="w-5 !h-5 rounded-full bg-gray-300 flex-shrink-0 transition-all border-gray border-[2px] relative"
                />
                <Skeleton variant="text" className="ml-2 !w-[30%]" />
              </div>
            </div>
            <Skeleton
              variant="rect"
              className="object-contain object-center !w-10/12 !h-20"
            />
          </div>
        </div>
        <div className="relative flex flex-col px-8 bg-white lg:px-0 ">
          <Skeleton
            variant="text"
            className="pb-3 font-serif text-3xl text-center"
          />
          <div className="text-left flex flex-row gap-x-5 mt-4">
            <div className="flex items-center space-x-2 cursor-pointer text-center">
              <Skeleton variant="circle" width={20} height={20} />
              <Skeleton variant="text" width={80} />
            </div>
            <div className="flex items-center space-x-2 cursor-pointer">
              <Skeleton variant="circle" width={20} height={20} />
              <Skeleton variant="text" width={80} />
            </div>
          </div>
          <div className="flex flex-col mt-6">
            <div className="flex flex-col w-full">
              <Skeleton variant="text" width={80} />
              <Skeleton variant="rect" className="w-full !h-10 mt-2" />
            </div>
            <div className="flex flex-col w-full mt-4">
              <Skeleton variant="text" width={80} />
              <Skeleton variant="rect" className="w-full !h-10 mt-2" />
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col w-full mt-4">
                <Skeleton variant="text" width={80} />
                <Skeleton variant="rect" className="w-[90%] !h-10 mt-2" />
              </div>
              <div className="flex flex-col w-full mt-4">
                <Skeleton variant="text" width={80} />
                <Skeleton variant="rect" className="w-[80%] !h-10 mt-2" />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col w-full mt-4">
                <Skeleton variant="text" width={80} />
                <Skeleton variant="rect" className="w-[70%] !h-10 mt-2" />
              </div>
              <div className="flex flex-col w-full mt-4">
                <Skeleton variant="text" width={80} />
                <Skeleton variant="rect" className="w-[90%] !h-10 mt-2" />
              </div>
            </div>
            <div className="flex flex-col w-full mt-4">
              <Skeleton variant="text" width={80} />
              <Skeleton variant="rect" className="w-full !h-10 mt-2" />
            </div>
            <div className="flex flex-col w-full mt-4 pb-8">
              <Skeleton variant="text" width={80} />
              <Skeleton variant="rect" className="w-full !h-10 mt-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TemplateLoader;
