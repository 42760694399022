import React from 'react';

interface PreloaderProps {
  small?: boolean;
}

const preloader: React.FC<PreloaderProps>= ({small}) => {
  return (
    <div id='page-preloader' className='preloader fixed top-0 left-0 w-full h-full bg-white z-20 opacity-[1] visible'>
      <div className={`loader ${!small ? `w-20 h-20` : 'w-3 h-3'} border-[10px] border-black border-solid rounded-[50%] border-t-[#707a4e] absolute left-1/2 top-1/2 translate-x-[150%] translate-y-[-50%]`}></div>
    </div>
  );
};

export default preloader;
