import { Disclosure, Transition } from "@headlessui/react";
import React from "react";

import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
import { formatMoney, classNames } from "../../utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TCartItem } from "../../types/types";

interface DetailedDescriptionProps {
  name: string;
  attributes: { nodes: [{ name: string; options: string[] }] };
}

export type DetailedProps = Partial<DetailedDescriptionProps> &
  Partial<TCartItem>;

const Detailed: React.FC<DetailedProps> = (product: DetailedProps) => {
  // console.log("prod", product.product);
  return (
    <div className="flex items-start mt-4 space-x-5">
      <div className='w-full'>
        <p className="font-semibold ml-2">
          {(product?.product as unknown as { price: string; name: string }).name}
        </p>
        <p className="font-semibold ml-2">
          {formatMoney(
            (product?.product as unknown as { price: string; name: string })
              .price
          )}
        </p>
        <Disclosure>
          {({ open }) => (
            <div className={open ? 'bg-[#f2f2f2] w-full pb-3 pl-2' : 'bg-transparent pl-2'}>
              <Disclosure.Button className="flex items-center py-2 space-x-2 text-black">
                {open ? (
                  <div className="w-5 h-5 rounded-full bg-white flex-shrink-0 transition-all border-gray border-[2px] relative">
                    <MinusIcon className="w-2 h-2 absolute top-[50%] left-[50%] mr-[-50%] translate-x-[-50%] translate-y-[-50%]" />
                  </div>
                ) : (
                  <div className="w-5 h-5 rounded-full bg-white flex-shrink-0 transition-all border-gray border-[2px] relative">
                    <PlusIcon className=" w-2 h-2 absolute top-[50%] left-[50%] mr-[-50%] translate-x-[-50%] translate-y-[-50%]" />
                  </div>
                )}
                <span className="ml-2">
                  detaillierten Inhalt {(product?.product as unknown as {name: string}).name}
                </span>
              </Disclosure.Button>
              <Transition
                enter="transition duration-300 ease-out origin-top"
                enterFrom="transform scale-y-0 opacity-0 "
                enterTo="transform scale-y-300 opacity-100"
                leave="transition duration-75 ease-out origin-top"
                leaveFrom="transform scale-y-100 opacity-100"
                leaveTo="transform scale-y-0 opacity-0"
              >
                <Disclosure.Panel className="">
                  <ul className="mt-3 space-y-1">
                    {(product?.product as unknown as {attributes: {nodes}}).attributes?.nodes
                      .map(
                        (n: { name: string; options: string[] }) => n.options
                      )
                      .map((featureGroup: string[], groupIndex: number) => (
                        <div
                          key={JSON.stringify(featureGroup)}
                          className="!mt-0"
                        >
                          {featureGroup
                            .filter((feature) => feature.length > 3)
                            .map((feature, featureIndex) => (
                              <li
                                key={groupIndex * 100 + featureIndex}
                                className={classNames(
                                  "flex text-gray-700",
                                  "text-sm",
                                  featureIndex === featureGroup.length - 1 &&
                                    groupIndex !==
                                      (product.product as unknown as {attributes: {nodes: []}}).attributes.nodes.length -
                                        1
                                    ? ""
                                    : ""
                                )}
                              >
                                <span className="">{feature}</span>
                              </li>
                            ))}
                        </div>
                      ))}
                  </ul>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default Detailed;
