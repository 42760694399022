//@ts-nocheck
import { graphql, navigate, PageProps, useStaticQuery } from "gatsby";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Container from "../components/container";
import { Product } from "../components/product/Product";
import { Coupon } from "../components/product/Coupon";
import { gql, useMutation, useQuery } from "@apollo/client";
import { client } from "../context/apollo.context";
import useCart from "../context/cart.context";
import { OldProduct } from "../components/product/OldProduct";
import { VALIDATE_VOUCHER } from "../mutations";
import useIsomorphicLayoutEffect from "../hooks/useIsomorpicLayoutEffect";
import { useVoucherData } from "../context/voucher-data.context";

const que = gql`
  query Product($slug: ID!) {
    product(id: $slug, idType: SLUG) {
      ... on BundleProduct {
        id
        databaseId
        name
        price
        description
        shortDescription
        bundleItems {
          edges {
            node {
              id
              databaseId
              image {
                sourceUrl
              }
              name
              slug
            }
            quantityMax
            quantityMin
            bundledItemId
          }
        }
      }
    }
  }
`;

const WpProductTemplate = ({
  data: { wpProduct },
}: PageProps<{ wpProduct }>) => {
  const [data, setData] = React.useState<any>(null);
  const { cart, lastVoucherCode, zipCode } = useCart();
  const [checkVoucher] = useMutation(VALIDATE_VOUCHER);
  const {changeVoucherData} = useVoucherData();
  const [oldVoucher, setOldVoucher] = React.useState<any>(null);
  const [zipCodeInLocalStorage, setZipCodeInLocalStorage] = useState<boolean>(!!(typeof window !== "undefined" && localStorage.getItem("zipCode")) || !!zipCode);

  useIsomorphicLayoutEffect(() => {
    if (!data) {
      client
        .query({
          query: que,
          variables: { slug: wpProduct.slug },
        })
        .then((res) => {
          setData(res.data.product);
        });
    }
  }, []);

  // 38 - brunch-bestellen,
  // 471 - outdated(OLD) brunch-bestellen
  // 375 - vouchers

  const productCategory = wpProduct?.productCategories?.nodes[0]?.databaseId;
  const brunchbox = productCategory === 38;
  const brunchboxOld = productCategory === 471;
  const voucher = productCategory === 375;

  useIsomorphicLayoutEffect(() => {
    lastVoucherCode &&
      checkVoucher({
        variables: {
          code: lastVoucherCode,
        },
      })
        .then((res) => {
          setOldVoucher(res.data.validateVoucher);
        })
        .catch((err) => {
          console.log(err);
          setOldVoucher(null);
        });
  }, [lastVoucherCode]);

  const lastVoucherCodeStartsWithOld = lastVoucherCode.startsWith("OLD");

  useIsomorphicLayoutEffect(() => {
    !brunchbox &&
      !voucher &&
      !lastVoucherCodeStartsWithOld &&
      typeof window !== "undefined" &&
      navigate("/404");
    !zipCodeInLocalStorage && navigate("/")
  }, []);

  useEffect(() => {
    return () => changeVoucherData();
  }, [])

  return (
    <Container>
      {/*{typeof window !== "undefined" &&*/}
      {/*window.location.pathname.includes("gutscheine") ? (*/}
      {/*  <Coupon data={wpProduct} alter={data} />*/}
      {/*) : (*/}
      {/*  <Product data={wpProduct} alter={data} />*/}
      {/*)}*/}
      {brunchbox && <Product data={wpProduct} alter={data} />}
      {brunchboxOld &&
        lastVoucherCodeStartsWithOld &&
        oldVoucher &&
        oldVoucher.valid && (
          <OldProduct data={wpProduct} alter={data} oldVoucher={oldVoucher} />
        )}
      {voucher && <Coupon data={wpProduct} alter={data} />}
    </Container>
  );
};

export const query = graphql`
  query ($slug: String!) {
    wpProduct(slug: { eq: $slug }) {
      heroImage {
        heroImage {
          altText
          sourceUrl
        }
      }
      id
      name
      slug
      description
      productCategories {
        nodes {
          databaseId
        }
      }
      shortDescription
      attributes {
        nodes {
          name
          options
        }
      }
      metaData {
        key
        value
      }
      image {
        sourceUrl
      }
      ... on WpBundleProduct {
        id
        databaseId
        name
        price
        bundleItems {
          nodes {
            ... on WpSimpleProduct {
              id
              databaseId
              image {
                sourceUrl
              }
              name
              slug
              price
            }
          }
        }
      }
    }
  }
`;

export default WpProductTemplate;
