import React from 'react';
// import Vorschau from '../images/VORSCHAU.svg';

interface PdfModalViewProps {
  text: string;
  product: any;
  bundle: {
    productId: string;
    upsellProducts: any[];
    text;
  };
}

const PdfModalView: React.FC<PdfModalViewProps> = ({
  text,
  product,
  bundle,
}) => {
  const metaDataSubtitle = product.metaData.find(
    (item) => item.key === 'subtitle'
  );
  const metaDataSubtitleValue = metaDataSubtitle && metaDataSubtitle.value;
  const replacedPrice = product.price.replace('CHF&nbsp;', '');
  const price = parseFloat(replacedPrice);
  const upsellProducts = bundle.upsellProducts;
  const upsellProductsPrice = upsellProducts.map((item) => {
    const replacedPrice = String(item.price).replace('CHF&nbsp;', '');
    return parseFloat(String(Number(replacedPrice) * Number(item.quantity)));
  });
  const totalPrice = upsellProductsPrice
    .reduce((a, b) => a + b, price)
    .toFixed(2);
  const date = new Date();
  const nextYear = date.setFullYear(date.getFullYear() + 5);
  const nextYearDate = new Date(nextYear);
  const nextYearDateString = nextYearDate.toLocaleDateString();

  return (
    <section className="w-full border-[40px] border-[#7e6551]">
      <div className="w-full">
        <img src="/logo-pdf.png" alt="" className="w-[260px]" />
      </div>
      <div className="w-ful flex justify-center flex-col h-[580px] m-auto items-center border-b-[20px] border-b-[#7e6551] relative">
        <img
          src={product?.image?.sourceUrl || "https://ru06wamdpv.preview.infomaniak.website/wp-content/uploads/2022/03/BrunchButler-Brunch-Box-William.png"}
          alt="product-img"
          className="w-[60%]"
        />
        <h1 className="text-[32px] my-8 uppercase text-center">
          Gutschein für einen Brunch
        </h1>
        <div className="absolute bottom-[42%] w-full">
          <img
            src="/text-pdf.png"
            alt="text-pdf"
            className="w-full h-auto z-[1000] rotate-28"
          />
        </div>
      </div>
      <div className="border-t-[20px] border-t-[#7e6551] h-[600px] relative">
        <div className="flex pt-12 pr-8">
          <div className="w-[30%]"></div>
          <div className="w-[70%]">
            <h2 className="text-left font-bold text-lg">
              Gutschein für einen Brunch
            </h2>
            <p style={{ fontSize: '14px', paddingTop: 10 }}>
              {product.name + ` (${metaDataSubtitleValue})`}
            </p>
            {product && product.bundleItems && product.bundleItems.nodes && (
              <>
                {bundle.upsellProducts.map((item, index) => {
                  return (
                    <p style={{ fontSize: '12px' }} key={index}>
                      {item.quantity} {item.name}
                    </p>
                  );
                })}
              </>
            )}
            <p className=" mt-3 text-sm">Im Wert von CHF {totalPrice}</p>
            <p className="mt-3 mb-8 text-sm">
              Einzulösen auf www.brunchbutler.ch mit folgendem Gutscheincode:
            </p>
          </div>
        </div>
        <div className="flex pr-8">
          <div className="w-[30%]">
            <img src="/logo-pdf.png" alt="logo-pdf" className="w-[260px] " />
          </div>
          <div className="w-[70%] border-t-2 border-dotted border-t-[#000000] mt-8 pt-8">
            <div className="h-[200px]">
              <p className="font-[500] pt-3 text-sm text-black italic break-all">
                {text}
              </p>
            </div>
            <div className="absolute bottom-8 w-[68%]">
            <div className="flex justify-between w-full">
              <div className="text-xs font-semibold">
                Gültig bis {nextYearDateString} Der Gutschein kann auch für
                andere BrunchButler-Leistungen eingelöst werden
              </div>
              <div className=" text-xs font-semibold">brunchbutler.ch</div>
            </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full top-[240px] left-0 z-[1000]">
          <img
            src="/text-pdf.png"
            alt="text-pdf"
            className="w-full h-auto z-[1000] rotate-26"
          />
        </div>
        {/* <Vorschau className="absolute -bottom-[55%] w-[112%] left-[3%] h-full z-[1000]" />
        </div> */}
      </div>
    </section>
  );
};

export default PdfModalView;
