import React, { FC } from "react";
import { ProductListItem } from "./ProductListItem";

interface IProductListProps {
  bundleItems?: {
    edges: any;
    nodes: {
      databaseId: string | number;
      id: string;
      image: { sourceUrl: string };
      name: string;
      price: string;
      slug: string;
    }[];
  };
  dataItems?: any;
  addUpsellProduct: (
    productId: number,
    quantity: number,
    price: number,
    item: any,
    name: string
  ) => void;
  removeUpsellProduct: (productId: number) => void;
  items?: any[];
  voucherData?: any[] | null;
}

export const ProductList: FC<IProductListProps> = ({
  bundleItems,
  dataItems,
  addUpsellProduct,
  removeUpsellProduct,
  voucherData,
}) => {
  const sortedBundleItems = bundleItems?.edges?.map((item: any, idx) => {
      return {
        ...item.node,
        quantityMax: item.quantityMax,
        idx,
        bundledItemId: item.bundledItemId,
        price: dataItems?.nodes[idx].price,
      }
  });
  const filteredBundleItems = sortedBundleItems?.filter((item: any) => item.idx <= 2);
  // if you want to use all items, use just sortedBundleItems

  return (
    <ul className="border-b-2 border-black divide-y-2 divide-black">
      {filteredBundleItems?.length && filteredBundleItems.map((item, index) => {
        const supplementInVoucher = voucherData?.find((voucherItem) => voucherItem?.bundledItemId == item?.bundledItemId);
        return (
          <ProductListItem
            addUpsellProduct={addUpsellProduct}
            removeUpsellProduct={removeUpsellProduct}
            item={item}
            key={index}
            defaultValue={supplementInVoucher?.quantity || 0}
          />
        )})}
    </ul>
  );
};
