import { RadioGroup } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import Modal from "../modal";
import Title from "../title";
import Detailed from "./Detailed";
import ProductForm from "./ProductForm";
import { ProductList } from "./ProductList";
import {
  ShippingAddressState,
  useUserData,
} from "../../context/user-data.context";
import Preloader from "../Preloader";
import { navigate } from "gatsby";

export const OldProduct = ({ data, alter, oldVoucher }) => {
  const [showModal, setShowModal] = useState(false);
  const [variant, setVariant] = useState("standart");
  const [note, setNote] = useState("");
  const { userData: shippingAddress, setUserData: setShippingAddress } =
    useUserData();
  const [currentUser, setCurrentUser] = useState<ShippingAddressState>(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log("using old product template", oldVoucher);

  (!oldVoucher || !oldVoucher.valid) && navigate("/404");
  // const urlParams = typeof window !== undefined && new URLSearchParams(window.location.search);
  const metaDataSubtitle = data.metaData.find(
    (item) => item.key === "subtitle"
  );
  const metaDataSubtitleValue = metaDataSubtitle && metaDataSubtitle.value;

  const initialState = {
    productId: data.databaseId,
    upsellProducts: [],
    note,
    variant,
  };
  const [bundle, setBundle] = useState(initialState);

  useEffect(() => {
    setBundle({ ...bundle, note, variant });
  }, [note, variant]);

  useEffect(() => {
    console.log("bundle", bundle);
  }, [bundle]);

  const ref = React.createRef<HTMLButtonElement>();

  const addUpsellProduct = (
    productId: number,
    quantity: number,
    price: number,
    bundledItemId: number,
    name: string
  ) => {
    setIsLoading(true);
    if (
      bundle.upsellProducts.find(
        (upsellProduct) => upsellProduct.productId === productId
      ) !== undefined
    ) {
      const newUpsellProducts = bundle.upsellProducts.map((upsellProduct) => {
        if (upsellProduct.productId === productId) {
          return {
            ...upsellProduct,
            quantity,
            price: price * quantity,
          };
        }
        return upsellProduct;
      });
      setBundle({
        ...bundle,
        upsellProducts: newUpsellProducts,
      });
    } else {
      setBundle({
        ...bundle,
        upsellProducts: [
          ...bundle.upsellProducts.filter((u) => u.productId !== productId),
          { productId, quantity, price, bundledItemId, name },
        ],
      });
    }
    setIsLoading(false);
  };
  const removeUpsellProduct = (productId: number) => {
    setIsLoading(true);
    setBundle({
      ...bundle,
      upsellProducts: bundle.upsellProducts.filter(
        (upsellProduct) => upsellProduct.productId !== productId
      ),
    });
    setIsLoading(false);
  };
  useEffect(() => {
    // get old products from the oldVoucher from field supplements, and add them to bundle, if quantity > 0
    oldVoucher.supplements.forEach((supplement) => {
      if (supplement.quantity > 0) {
        addUpsellProduct(
          Number(supplement.product_id),
          supplement.quantity,
          supplement.price,
          supplement.bundledItemId,
          supplement.title
        );
      }
    });
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <div className="pt-12 pb-12 mx-auto max-w-7xl">
        <Title
          title={data?.name}
          subtitle={
            "It's old product, additional products will be added in the cart"
          }
          breadcrumb="Produkte"
        />
        <div className="gap-6 mt-12 transition-all duration-300 lg:grid lg:grid-cols-3">
          <div className="relative flex flex-col px-8 bg-white lg:px-0 ">
            <h2 className="pb-3 font-serif text-3xl text-center border-b-2 border-black">
              Ihr Brunch {/*{metaDataSubtitleValue}*/}
            </h2>
            <Detailed product={data} />
            <div className="relative flex flex-col pt-8 bg-white lg:pt-0 lg:px-0 mt-20">
              <h2 className="pb-3 font-serif text-3xl text-center border-b-2 border-black sm:pt-8 lg:pt-0">
                Supplements
              </h2>
              <ProductList
                bundleItems={alter?.bundleItems}
                dataItems={data?.bundleItems}
                addUpsellProduct={addUpsellProduct}
                removeUpsellProduct={removeUpsellProduct}
              />
            </div>
            <div>
              <p className="py-5 text-sm text-standardGray">
                ** Der Verkauf von Champagner an Personen unter 18 Jahren ist aus
                gesetzlichen Gründen untersagt. Mit der Auswahl eines
                Champagners bestätigen Sie, dass der/die EmpfängerIn 18 Jahre
                oder älter ist.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col  my-[50px] text-center">
            <div className="bg-[#f2f2f2] p-3 w-full">
              <figure className="w-full">
                <img
                  className="mx-auto w-full"
                  alt=""
                  src={
                    data?.heroImage?.heroImage?.sourceUrl ?? "/bestellen.jpg"
                  }
                />
              </figure>
              <div className="my-4 flex flex-col justify-center items-center text-center lg:px-0">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.shortDescription
                      ? data.shortDescription
                      : `Das ist unsere ${data?.name}`,
                  }}
                  className="font-bold text-[16px]"
                >
                  {/*{data?.shortDescription*/}
                  {/*  ? data?.shortDescription*/}
                  {/*  : `Das ist unsere ${data?.name}.`}*/}
                </p>
                <p
                  className="font-normal text-[16px]"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              </div>
              <div className="w-[98%] self-center py-5 space-y-5 sm:w-full">
                <p className="pb-2 font-serif text-2xl border-b-2 border-black">
                  Ihre Produktbemerkungen
                </p>
                <RadioGroup
                  value={variant}
                  onChange={setVariant}
                  className="text-left"
                >
                  <RadioGroup.Option value="standart">
                    {({ checked }) => (
                      <div className="flex items-center space-x-2 cursor-pointer">
                        <input
                          defaultChecked={checked}
                          className="text-darkGray focus:ring-0 cursor-pointer"
                          type="radio"
                          name="variant"
                          id="standart"
                        />
                        <span
                          className={!checked ? "text-standardGray" : undefined}
                        >
                          standard
                        </span>
                      </div>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="vegetarian">
                    {({ checked }) => (
                      <div className="flex items-center space-x-2 cursor-pointer">
                        <input
                          checked={checked}
                          className="text-darkGray focus:ring-0 cursor-pointer"
                          type="radio"
                          name="variant"
                          id="standart"
                        />
                        <span className={!checked && "text-standardGray"}>
                          vegetarisch (Fleischplatte wird ersetzt)
                        </span>
                      </div>
                    )}
                  </RadioGroup.Option>
                </RadioGroup>
                <textarea
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setNote(e.target.value)
                  }
                  className={`resize-none w-full outline-[#e2e2e2]  border-[2px] border-[#e2e2e2] bg-white !focus-within:outline-none !focus:ring-0 ${
                    !note ? "text-standardGray" : "text-black"
                  }`}
                  rows={3}
                  placeholder='Ihre Bemerkung, zum Beispiel "Ich bin allergisch auf...", "Bitte kein Schweinefleisch" etc. ...'
                >
                  {/* {cart.note && cart.note} */}
                </textarea>
              </div>
            </div>
          </div>
          <ProductForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            bundle={bundle}
            data={data}
            setShowModal={setShowModal}
            bundleItems={alter?.bundleItems}
          />
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        pCancelButtonRef={ref}
      />
    </>
  );
};
