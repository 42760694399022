// @ts-nocheck
import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment } from 'react';
import PdfModalView from './PdfModalView';
// import { PDFViewer } from "@react-pdf/renderer";

interface ModalPdfProps {
  show: boolean;
  setShow: (value: boolean) => void;
  children: JSX.Element;
  text: string;
  product: any;
  bundle: {
    productId: string;
    upsellProducts: any[];
    pdf;
  };
}

const ModalPdf: FC<ModalPdfProps> = ({
  show,
  setShow,
  children,
  text,
  product,
  bundle,
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-scroll"
        onClose={() => setShow(false)}
      >
        <div className="min-h-screen px-4 text-center bg">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block w-full h-[80vh] max-w-6xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl overflow-y-auto">
              {/* <PDFViewer className="w-full h-full">{children}</PDFViewer> */}
              <PdfModalView bundle={bundle} text={text} product={product} />

              <button
                type="button"
                className="absolute top-3 right-[10px] w-6 h-6"
                onClick={() => setShow(false)}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM12.59 6L10 8.59L7.41 6L6 7.41L8.59 10L6 12.59L7.41 14L10 11.41L12.59 14L14 12.59L11.41 10L14 7.41L12.59 6Z"
                    fill="#F8F8F8"
                  />
                </svg>
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalPdf;
