import React, { FC, useEffect } from "react";
import { formatMoney } from "../../utils";

interface ProductListItemProps {
  item: {
    bundledItemId: number;
    quantityMax: number;
    databaseId: string | number;
    id: string;
    image: { sourceUrl: string };
    name: string;
    price: string;
    slug: string;
  };
  addUpsellProduct: (
    productId: string | number,
    quantity: number,
    price: number,
    bundledItemId: number,
    name: string
  ) => void;
  removeUpsellProduct: (productId: string | number) => void;
  defaultValue?: number | null;
}

export const ProductListItem: FC<ProductListItemProps> = ({
  item,
  addUpsellProduct,
  removeUpsellProduct,
  defaultValue = null,
}) => {
  const productName = item?.name;
  const formatedProductName = productName?.replace(/\|/g, "\n");
  console.log(item.name, item.slug, item?.slug === "nzz-am-sonntag");

  return (
    <li className="flex py-6 sm:py-10">
      <div className="flex-shrink-0 mt-1 sm:mt-0">
        {item?.image && (
          <img
            // alt={item?.image.altText}
            src={item?.image?.sourceUrl}
            className="object-contain object-center w-16 h-16 sm:w-20 sm:h-20"
          />
        )}
      </div>

      <div className="flex flex-col justify-between flex-1 ml-4 sm:ml-9">
        <div className="relative sm:flex sm:pr-0">
          <div className="flex-1">
            <div className="flex space-x-1">
              <h3 className="text-sm">
                <span className="font-bold text-[16px] text-gray-700 whitespace-pre-line hover:text-gray-800">
                  {formatedProductName + `${(item?.slug === "nzz-am-sonntag" || item.slug === "nzz-am-sonntag-2") ? "" : '**'}`}
                </span>
              </h3>
              <span className="-mt-1">
                <p className="text-[14px] font-normal text-[#636363]">
                  {formatedProductName?.includes("Champagner") }
                </p>
              </span>
            </div>
            <p className="mt-1 font-bold text-[16px] text-gray-900">
              {formatMoney(item?.price)}
            </p>
          </div>

          <div className="mt-4 text-right sm:mt-0">
            <label htmlFor={`quantity-1`} className="sr-only">
              Menge, {item?.name}
            </label>
            <select
              defaultValue={defaultValue}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                Number(e.target.value) === 0
                  ? removeUpsellProduct(item.databaseId)
                  : addUpsellProduct(
                      item.databaseId,
                      Number(e.target.value),
                      +item.price.split(";")[1],
                      item.bundledItemId,
                      item.name
                    );
              }}
              className="max-w-full border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left  focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm w-[70px] h-9"
            >
              {Array.from(Array(item && item.quantityMax ? item.quantityMax + 1 : 1)).map((_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </li>
  );
};
