// @ts-nocheck
import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  // StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

interface PdfProps {
  text: string;
  product: any;
  bundle: {
    productId: string;
    upsellProducts: any[];
    pdf;
  };
}

Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fontStyle: 'italic',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-lightitalic-webfont.ttf',
});

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fontWeight: 'bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});



const PDF: React.FC<PdfProps> = ({ text, product, bundle }) => {
  const metaDataSubtitle = product.metaData.find(
    (item) => item.key === 'subtitle'
  );
  const metaDataSubtitleValue = metaDataSubtitle && metaDataSubtitle.value;
  const replacedPrice = product.price.replace('CHF&nbsp;', '');
  const price = parseFloat(replacedPrice);
  const upsellProducts = bundle.upsellProducts;
  const upsellProductsPrice = upsellProducts.map((item) => {
    const replacedPrice = String(item.price).replace('CHF&nbsp;', '');
    return parseFloat(replacedPrice);
  });
  const totalPrice = upsellProductsPrice
    .reduce((a, b) => a + b, price)
    .toFixed(2);
  const date = new Date();
  const nextYear = date.setFullYear(date.getFullYear() + 5);
  const nextYearDate = new Date(nextYear);
  const nextYearDateString = nextYearDate.toLocaleDateString();


  return (
    <Document>
      <Page style={{
        fontFamily: 'Roboto',
        lineHeight: '120%',
        width: '100%',
        margin: '0 auto',
        borderLeft: '20px solid #7e6551',
        borderRight: '20px solid #7e6551',
        borderBottom: '20px solid #7e6551',
        borderTop: '20px solid #7e6551',
      }}>
        <View
          style={{
            marginHorizontal: 0,
            borderBottom: '20px solid #7e6551',
            height: '45%',
            position: 'relative',
          }}
        >
          <View
            style={{
              width: '110%',
              position: 'absolute',
              bottom: '-55%',
              left: '3%',
              height: '100%',
              transform: 'translateX(-40%) translateY(-50%)',
              zIndex: 1000,
            }}
          >
            <Image
              src="/text-pdf.png"
              style={{
                width: '100%',
                height: 'auto',
                transform: 'rotate(-31deg)',
                zIndex: 1000,
              }}
              alt="text-pdf"
            />
          </View>
          <View
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: 0,
            }}
          >
            <Image
              src="/logo-pdf.png"
              style={{
                width: 160,
                position: 'absolute',
                Top: 30,
                Left: 30,
              }}
              alt="logo-pdf"
            />
            <View style={{ marginTop: 35 }}>
              <Image
                src={product.image.sourceUrl}
                style={{
                  width: 320,
                  height: 250,
                  margin: '0 auto',
                  zIndex: 1,
                }}
                alt="product"
              />
            </View>
            <Text
              style={{
                fontWeight: 'normal',
                color: 'black',
                fontSize: 20,
                marginTop: 30,
                textTransform: 'uppercase',
              }}
            >
              Gutschein für einen Brunch
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            height: '45%',
            // borderTop: '10px solid #7e6551',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <View
            style={{
              width: '112%',
              position: 'absolute',
              bottom: '-55%',
              left: '3%',
              height: '100%',
              transform: 'translateX(-40%) translateY(-50%)',
              zIndex: 5,
            }}
          >
            <Image
              src="/text-pdf.png"
              style={{
                width: '100%',
                height: 'auto',
                transform: 'rotate(-35deg)',
                zIndex: 10000,
              }}
              alt="text-pdf"
            />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              width: '100%',
              paddingHorizontal: 13,
              margin: '0 auto',
              marginVertical: '0 auto',
            }}
          >
            <View
              style={{
                flexBasis: '30%',
                display: 'flex',
                // fontSize: 8,
                height: '100%',
                position: 'relative',
              }}
            >
              <Image
                src="/QR-Code.png"
                style={{
                  width: 88,
                  height: 88,
                  margin: '0 auto',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',
                }}
                alt="QR-Code"
              />
            </View>
            <View
              style={{
                flexBasis: '80%',
                display: 'flex',
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  color: 'black',
                  fontSize: 12,
                }}
              >
                Gutschein für einen Brunch
              </Text>
              <Text style={{ fontSize: '12px', paddingTop: 10 }}>
                {product.name + `(${metaDataSubtitleValue})`}
              </Text>
              {product && product.bundleItems && product.bundleItems.nodes && (
                <>
                  {bundle.upsellProducts.map((item, index) => {
                    return (
                      <Text style={{ fontSize: '10px' }} key={index}>
                        {item.quantity} {item.name}
                      </Text>
                    );
                  })}
                </>
              )}
              <Text
                style={{
                  fontWeight: 'bold',
                  marginTop: '10px',
                  fontSize: '12px',
                }}
              >
                Im Wert von CHF {totalPrice}
              </Text>
              <Text
                style={{
                  marginTop: '10px',
                  fontSize: '10px',
                }}
              >
                Einzulösen auf www.brunchbutler.ch mit folgendem Gutscheincode:
              </Text>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              width: '100%',
              paddingHorizontal: 13,
              margin: '0 auto',
            }}
          >
            <View style={{ flexBasis: '30%' }}>
              <Image
                src="/logo-pdf.png"
                style={{
                  width: 160,
                  marginTop: 12,
                  zIndex: 1,
                }}
                alt="logo-pdf"
              />
            </View>
            <div style={{ display: 'flex', flexBasis: '65%' }}>
              <div
                style={{
                  paddingTop: 30,
                  borderBottom: '1px dotted gray',
                }}
              ></div>
            </div>
          </View>
          <View style={{ marginLeft: '30%' }}>
            <Text
              style={{
                fontFamily: 'Roboto',
                paddingTop: 10,
                fontSize: '10px',
                fontStyle: 'italic',
                color: 'black',
                paddingRight: 30,
              }}
            >
              {text}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: 60,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  flexBasis: '80%',
                }}
              >
                <Text
                  style={{
                    fontSize: '6px',
                    fontWeight: 'light',
                  }}
                >
                  Gültig bis {nextYearDateString} Der Gutschein kann auch für
                  andere BrunchButler-Leistungen eingelöst werden
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  flexBasis: '20%',
                }}
              >
                <Text style={{ fontSize: '6px', fontWeight: 'light' }}>
                  branchbutler.ch
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDF;
